// Generated by Framer (d894e1b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/ZZdJWJfOQ";

const cycleOrder = ["gNizi_jmI"];

const serializationHash = "framer-O0dMc"

const variantClassNames = {gNizi_jmI: "framer-v-qrl8wn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, mxGGZ6Bi4: title ?? props.mxGGZ6Bi4 ?? "FEATURED"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, mxGGZ6Bi4, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "gNizi_jmI", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-O0dMc", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-qrl8wn", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"gNizi_jmI"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-58389c29-c015-4427-a57c-637e65cb6404, rgb(235, 235, 235))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-7y1zex"} data-styles-preset={"ZZdJWJfOQ"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-e0da01bd-4e0f-4d83-9396-a2ca91f1ef19, rgb(0, 0, 0)))"}}>FEATURED</motion.p></React.Fragment>} className={"framer-1ezjqso"} layoutDependency={layoutDependency} layoutId={"fzppknLjc"} style={{"--extracted-r6o4lv": "var(--token-e0da01bd-4e0f-4d83-9396-a2ca91f1ef19, rgb(0, 0, 0))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={mxGGZ6Bi4} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-O0dMc [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-O0dMc .framer-1drfoie { display: block; }", ".framer-O0dMc .framer-qrl8wn { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 8px 8px 8px 8px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-O0dMc .framer-1ezjqso { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-O0dMc .framer-qrl8wn { gap: 0px; } .framer-O0dMc .framer-qrl8wn > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-O0dMc .framer-qrl8wn > :first-child { margin-left: 0px; } .framer-O0dMc .framer-qrl8wn > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 96.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"mxGGZ6Bi4":"title"}
 * @framerImmutableVariables false
 */
const FramerXzTHLtAYf: React.ComponentType<Props> = withCSS(Component, css, "framer-O0dMc") as typeof Component;
export default FramerXzTHLtAYf;

FramerXzTHLtAYf.displayName = "CMS / Featured";

FramerXzTHLtAYf.defaultProps = {height: 28, width: 96.5};

addPropertyControls(FramerXzTHLtAYf, {mxGGZ6Bi4: {defaultValue: "FEATURED", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerXzTHLtAYf, [...sharedStyle.fonts])